import { debug } from "./debug";
import { Navigation } from "./navigation";
import { Router } from "./router";
import player from "mediaelement/src/js/player";

// -----------------------------------------------------------------------------
//  Internal functions
// -----------------------------------------------------------------------------
const internal = {

    pauseAllVideos: () => {
        if (window.berlinovo.videos.homeBg) {
            window.berlinovo.videos.homeBg.pause();
        }
        if (window.berlinovo.videos.developmentBg) {
            window.berlinovo.videos.developmentBg.pause();
        }
        if (window.berlinovo.videos.developmentFull) {
            window.berlinovo.videos.developmentFull.pause();
        }
        if (window.berlinovo.videos.restructuringBg) {
            window.berlinovo.videos.restructuringBg.pause();
        }
        if (window.berlinovo.videos.restructuringFull) {
            window.berlinovo.videos.restructuringFull.pause();
        }
        if (window.berlinovo.videos.sustainabilityBg) {
            window.berlinovo.videos.sustainabilityBg.pause();
        }
        if (window.berlinovo.videos.sustainabilityFull) {
            window.berlinovo.videos.sustainabilityFull.pause();
        }
    },

    initBgVideo: function (varName, element) {
        if (window.berlinovo.videos[varName]) {
            // play
            window.berlinovo.videos[varName].play();
        } else {
            // init
            $(element).mediaelementplayer({
                streching: 'fill',
                clickToPlayPause: false,
                setDimensions: true,
                controlsTimeoutDefault: 0,
                hideVideoControlsOnLoad: true,
                hideVideoControlsOnPause: true,
                success: function (media) {
                    window.berlinovo.videos[varName] = media;
                    window.berlinovo.videos[varName].play();
                },
                error: function () {
                    window.berlinovo.videos[varName] = null;
                }
            });
        }
    },

    /**
     * process home section
     */
    processHome: () => {

        Navigation.highlightNav('.sidebar-nav__link--home');
        //Navigation.switchContact('.sub-bar-nav__text--contact.general');

        let container = $('#home');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.home = false;
        }

        if (!berlinovo.preloaded.home) {

            $.ajax({
                url: "sections/home.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section home');
                berlinovo.preloaded.home = true;

                // bg video
                internal.initBgVideo('homeBg', '#home-bg-video');
            });
        } else {
            // bg video
            internal.initBgVideo('homeBg', '#home-bg-video');
        }
    },

    /**
     * process report1 section
     */
    processReport1: () => {

        Navigation.highlightNav('.sidebar-nav__link--report');
        //Navigation.switchContact('.sub-bar-nav__text--contact.report');

        let container = $('#report1');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.report1 = false;
        }

        if (!berlinovo.preloaded.report1) {

            $.ajax({
                url: "sections/report1.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section report1');
                berlinovo.preloaded.report1 = true;

                // slideIn Sprechblase
                let bubble = $('.report1 .bubble');

                if (!bubble.hasClass('visible')) {
                    setTimeout(function () {
                        bubble.addClass('visible');
                    }, 500);
                }
            });
        }
    },

    /**
     * process report2 section
     */
    processReport2: () => {

        Navigation.highlightNav('.sidebar-nav__link--report');
        //Navigation.switchContact('.sub-bar-nav__text--contact.report');

        let container = $('#report2');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.report2 = false;
        }

        if (!berlinovo.preloaded.report2) {

            $.ajax({
                url: "sections/report2.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section report2');
                berlinovo.preloaded.report2 = true;

                // slideIn Sprechblase
                let bubble = $('.report2 .bubble');

                if (!bubble.hasClass('visible')) {
                    setTimeout(function () {
                        bubble.addClass('visible');
                    }, 500);
                }

            });
        }
    },

    /**
     * process development1 section
     */
    processDevelopment1: () => {

        Navigation.highlightNav('.sidebar-nav__link--development');
        //Navigation.switchContact('.sub-bar-nav__text--contact.development');

        let container = $('#development1');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.development1 = false;
        }

        if (!berlinovo.preloaded.development1) {

            $.ajax({
                url: "sections/development1.html",
                context: document.body
            }).done(function (data) {

                let container = $('#development1');
                container.find('.section-loader').fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section development1');
                berlinovo.preloaded.development1 = true;

                // slideIn Sprechblase
                let bubble = $('.development1 .bubble');

                if (!bubble.hasClass('visible')) {
                    setTimeout(function () {
                        bubble.addClass('visible');
                    }, 500);
                }
            });
        }
    },

    /**
     * process development2 section
     */
    processDevelopment2: () => {

        Navigation.highlightNav('.sidebar-nav__link--development');
        //Navigation.switchContact('.sub-bar-nav__text--contact.development');

        let container = $('#development2');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.development2 = false;
        }

        if (!berlinovo.preloaded.development2) {

            $.ajax({
                url: "sections/development2.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section development2');
                berlinovo.preloaded.development2 = true;

                // bg video
                internal.initBgVideo('developmentBg', '#development-bg-video');
            });
        } else {
            // bg video
            internal.initBgVideo('developmentBg', '#development-bg-video');
        }
    },

    /**
     * process restructuring1 section
     */
    processRestructuring1: () => {

        Navigation.highlightNav('.sidebar-nav__link--restructuring');
        //Navigation.switchContact('.sub-bar-nav__text--contact.restructuring');

        let container = $('#restructuring1');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.restructuring1 = false;
        }

        if (!berlinovo.preloaded.restructuring1) {

            $.ajax({
                url: "sections/restructuring1.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section restructuring1');
                berlinovo.preloaded.restructuring1 = true;

                // slideIn Sprechblase
                let bubble = $('.restructuring1 .bubble');

                if (!bubble.hasClass('visible')) {
                    setTimeout(function () {
                        bubble.addClass('visible');
                    }, 500);
                }
            });
        }
    },

    /**
     * process restructuring2 section
     */
    processRestructuring2: () => {

        Navigation.highlightNav('.sidebar-nav__link--restructuring');
        //Navigation.switchContact('.sub-bar-nav__text--contact.restructuring');

        let container = $('#restructuring2');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.restructuring2 = false;
        }

        if (!berlinovo.preloaded.restructuring2) {

            $.ajax({
                url: "sections/restructuring2.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section restructuring2');
                berlinovo.preloaded.restructuring2 = true;

                // bg video
                internal.initBgVideo('restructuringBg', '#restructuring-bg-video');
            });
        } else {
            // bg video
            internal.initBgVideo('restructuringBg', '#restructuring-bg-video');
        }
    },

    /**
     * process sustainability1 section
     */
    processSustainability1: () => {

        Navigation.highlightNav('.sidebar-nav__link--sustainability');
        //Navigation.switchContact('.sub-bar-nav__text--contact.sustainability');

        let container = $('#sustainability1');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.sustainability1 = false;
        }

        if (!berlinovo.preloaded.sustainability1) {

            $.ajax({
                url: "sections/sustainability1.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section sustainability1');
                berlinovo.preloaded.sustainability1 = true;

                // slideIn Sprechblase
                let bubble = $('.sustainability1 .bubble');

                if (!bubble.hasClass('visible')) {
                    setTimeout(function () {
                        bubble.addClass('visible');
                    }, 500);
                }
            });
        }
    },

    /**
     * process sustainability2 section
     */
    processSustainability2: () => {

        Navigation.highlightNav('.sidebar-nav__link--sustainability');
        //Navigation.switchContact('.sub-bar-nav__text--contact.sustainability');

        let container = $('#sustainability2');
        let loader = container.find('.section-loader');
        if (loader.length > 0) {
            berlinovo.preloaded.sustainability2 = false;
        }

        if (!berlinovo.preloaded.sustainability2) {

            $.ajax({
                url: "sections/sustainability2.html",
                context: document.body
            }).done(function (data) {

                loader.fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded section sustainability2');
                berlinovo.preloaded.sustainability2 = true;

                // bg video
                internal.initBgVideo('sustainabilityBg', '#sustainability-bg-video');
            });
        } else {
            // bg video
            internal.initBgVideo('sustainabilityBg', '#sustainability-bg-video');
        }
    }
}

// -----------------------------------------------------------------------------
//  External functions
// -----------------------------------------------------------------------------
export const Section = {
    /**
     * Loops all sections an checks which one is most visible
     */
    checkSectionPos: () => {

        let visibleOne = {};
        let visibleTwo = {};

        for (let i in window.berlinovo.sections) {
            let name = window.berlinovo.sections[i].name;
            var element = $(name);
            var top_of_element = element.offset().top;
            var bottom_of_element = element.offset().top + element.outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            window.berlinovo.sections[i].top = (bottom_of_screen - top_of_element);
            window.berlinovo.sections[i].bottom = (bottom_of_element - top_of_screen);

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                // the element is visible
                window.berlinovo.sections[i].visible = true;

                if ($.isEmptyObject(visibleOne)) {
                    visibleOne = window.berlinovo.sections[i];
                } else if ($.isEmptyObject(visibleTwo)) {
                    visibleTwo = window.berlinovo.sections[i];
                }
            } else {
                // the element is not visible
                window.berlinovo.sections[i].visible = false;
            }
            //debug(window.berlinovo.sections[i]);
        }

        // check the 2 visible sections
        window.berlinovo.currentSection = visibleOne.name;
        window.berlinovo.scrollSize = window.berlinovo.scrollSize || 100;
        if (!$.isEmptyObject(visibleOne) && !$.isEmptyObject(visibleTwo)) {
            if (window.berlinovo.scrollDirection === 'down') {
                if (visibleTwo.top > window.berlinovo.scrollSize) {
                    window.berlinovo.currentSection = visibleTwo.name;
                } else {
                    window.berlinovo.currentSection = visibleOne.name;
                }
            } else {
                if (visibleOne.bottom > window.berlinovo.scrollSize) {
                    window.berlinovo.currentSection = visibleOne.name;
                } else {
                    window.berlinovo.currentSection = visibleTwo.name;
                }
            }
        }
    },

    initBgVideo: function (varName, element) {
        internal.initBgVideo(varName, element);
    },

    /**
     * Process Sections after loading
     */
    processSection: () => {
        let destination = window.berlinovo.currentSection;

        if (destination.length !== 0 && destination !== '') {
            // close all opened detail pages and overlays
            $('.sidebar--back').hide();
            $('.section--overlay').removeClass('visible');

            Router.setRoute(destination);
            internal.pauseAllVideos();

            // process section
            switch (destination) {
                case '#home':
                    internal.processHome();
                    break;
                case '#report1':
                    internal.processReport1();
                    break;
                case '#report2':
                    internal.processReport2();
                    break;
                case '#restructuring1':
                    internal.processRestructuring1();
                    break;
                case '#restructuring2':
                    internal.processRestructuring2();
                    break;
                case '#development1':
                    internal.processDevelopment1();
                    break;
                case '#development2':
                    internal.processDevelopment2();
                    break;
                case '#sustainability1':
                    internal.processSustainability1();
                    break;
                case '#sustainability2':
                    internal.processSustainability2();
                    break;
            }
        }
    },

    setRatio: () => {
        let body = $('body');
        body.removeClass([
            'landscape-16-10',
            'landscape-16-9',
            'landscape-4-3',
            'portrait-4-3',
            'portrait-16-9',
            'portrait-16-10',
            'ratio-unknown'
        ]);

        let width = Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
        );
        let height = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );

        let ratio = height / width;
        //console.log(ratio);
        if (ratio < 0.5) {
            body.addClass('landscape-16-10');
        } else if (ratio >= 0.5 && ratio < 0.75) {
            body.addClass('landscape-16-9');
        } else if (ratio >= 0.75 && ratio < 1) {
            body.addClass('landscape-4-3');
        } else if (ratio > 1 && ratio <= 1.33334) {
            body.addClass('portrait-4-3');
        } else if (ratio > 1.33334 && ratio <= 1.778) {
            body.addClass('portrait-16-9');
        } else if (ratio > 1.778 && ratio < 2.2) {
            body.addClass('portrait-16-10');
        } else {
            body.addClass('ratio-unknown');
        }
    }
}
