'use strict';

import '../scss/app.scss';

import '../../node_modules/jquery.scrollto/jquery.scrollTo';
import '../../node_modules/slick-carousel/slick/slick.js';

import '../../node_modules/mediaelement/build/mediaelement-and-player.js';
import '../../node_modules/mediaelement/build/lang/de.js';
import '../../node_modules/mediaelement/src/css/mediaelementplayer.css';
import '../../node_modules/mediaelement/src/css/mejs-controls.svg';
import '../../node_modules/mediaelement/src/css/mejs-controls.png';

import { Section } from './module/sections';
import { Navigation } from "./module/navigation";

// -----------------------------------------------------------------------------------
//  init section handling
// -----------------------------------------------------------------------------------
window.berlinovo = {
    lastScrollTop: 0,
    scrollSize: 160,  // Distance from edge to trigger auto scroll
    checkScroll: true,
    sections: [
        {
            name: '#home',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#report1',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#report2',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#restructuring1',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#restructuring2',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#development1',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#development2',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#sustainability1',
            visible: false,
            top: 0,
            bottom: 0,
        },
        {
            name: '#sustainability2',
            visible: false,
            top: 0,
            bottom: 0,
        },
    ],
    elements: {
        sidebar: null,
        sidebarContact: null,
        sidebarDownload: null,
    },
    preloaded: {
        home: false,
        report1: false,
        report2: false,
        reportDetail: false,
        development1: false,
        development2: false,
        developmentDetail: false,
        restructuring1: false,
        restructuring2: false,
        restructuringDetail: false,
        sustainability1: false,
        sustainability2: false,
        sustainabilityDetail: false
    },
    videos: {
        homeBg: false,
        developmentBg: false,
        developmentFull: false,
        restructuringBg: false,
        restructuringFull: false,
        sustainabilityBg: false,
        sustainabilityFull: false
    },
    isIE: false
};

$(function () {

    // -----------------------------------------------------------------------------------
    //  intercept links
    // -----------------------------------------------------------------------------------
    $(document).on('click', '.js-jumpto', function (e) {
        e.preventDefault();

        Navigation.closeDetail();
        Navigation.closeAllSubmenues();

        let href = $(this).attr('href');

        if (href === undefined) {
            href = e.currentTarget.getAttribute('xlink:href');
        }

        Navigation.jumpTo(href);
    });

    // -----------------------------------------------------------------------------------
    //  observe scrolling
    // -----------------------------------------------------------------------------------
    $('#sections').on('scroll', function () {

        // check if scroll detection is activated
        if (window.berlinovo.checkScroll) {

            var st = $(this).scrollTop();
            if (st > window.berlinovo.lastScrollTop) {
                window.berlinovo.scrollDirection = 'down';
            } else {
                window.berlinovo.scrollDirection = 'up';
            }
            window.berlinovo.lastScrollTop = st;

            clearTimeout($.data(this, 'scrollCheck'));
            $.data(this, 'scrollCheck', setTimeout(function () {
                console.log('finish scrolling');

                Section.checkSectionPos();

                setTimeout(function () {
                    window.berlinovo.checkScroll = false; // deactivate scroll detection
                    $('#sections').stop().scrollTo(window.berlinovo.currentSection, 300, function () {
                        Section.processSection();
                        setTimeout(function () {
                            window.berlinovo.checkScroll = true; // reactivate scroll detection
                        }, 66);
                    });
                }, 66);
            }, 200));
        }
    });

    // -----------------------------------------------------------------------------------
    //  open/close menu
    // -----------------------------------------------------------------------------------
    $('.js-toggle-menu').on('click', function () {
        if (Navigation.checkOneSidebar()) {
            Navigation.closeAllSubmenues();
        } else {
            Navigation.openSidebar();
        }
    });
    $('.js-close-menu').on('click', function () {
        Navigation.closeAllSubmenues();
    });
    // -------------------------------------------------- Mobile
    $('.js-toggle-mmenu').on('click', function () {
            Navigation.toggleMobile();
    });
    $('.js-close-mmenu').on('click', function () {
        Navigation.closeAllSubmenues();
    });
    $('.js-toggle-accordion').on('click', function() {
        $(this).next().toggleClass('visible');
    });
    // -------------------------------------------------- Contact
    $('.js-toggle-contact').on('click', function () {
        if (Navigation.checkSidebarContact()) {
            Navigation.closeAllSubmenues();
        } else {
            Navigation.closeAllSubmenues();
            Navigation.openSidebarContact();
        }
    });
    $('.js-close-contact').on('click', function () {
        Navigation.closeSidebarContact();
    });
    // -------------------------------------------------- Download
    $('.js-toggle-download').on('click', function () {
        if (Navigation.checkSidebarDownload()) {
            Navigation.closeAllSubmenues();
        } else {
            Navigation.closeAllSubmenues();
            Navigation.openSidebarDownload();
        }
    });
    $('.js-close-download').on('click', function () {
        Navigation.closeSidebarDownload();
    });

    // -----------------------------------------------------------------------------------
    //  open/close detail pages
    // -----------------------------------------------------------------------------------
    $(document).on('click', '.js-open-detail', function (e) {
        e.preventDefault;

        // load data into details container
        let detail = $(this).attr('href').replace('/', '_');
        if (detail) {
            Navigation.openDetail(detail);
        }

    });
    $(document).on('click', '.js-close-detail', function (e) {
        e.preventDefault;

        Navigation.closeDetail();
    });

    // -----------------------------------------------------------------------------------
    //  open/close video overlays
    // -----------------------------------------------------------------------------------
    $(document).on('click', '.js-open-overlay', function (e) {
        e.preventDefault;

        let overlayName = $(this).data('overlay');
        if(overlayName) {
            Navigation.openOverlay(overlayName);
        }

    });
    $(document).on('click', '.js-close-overlay', function (e) {
        e.preventDefault;

        Navigation.closeOverlay();
    });

    // -----------------------------------------------------------------------------------
    //  Slick - needs no get Reinitialized on window Resize after it was destroyed
    // -----------------------------------------------------------------------------------
    $(window).on('resize orientationchange', function () {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function(){
            Section.checkSectionPos();
            Section.setRatio();

            setTimeout(function () {
                window.berlinovo.checkScroll = false; // deactivate scroll detection
                $('#sections').stop().scrollTo(window.berlinovo.currentSection, 300, function () {
                    Section.processSection();
                    setTimeout(function () {
                        window.berlinovo.checkScroll = true; // reactivate scroll detection
                    }, 66);
                });
            }, 66);

            $('.slider').each(function () {
                var $slider = $(this);
                // Initializes a slick carousel only on mobile screens
                if ($(window).width() < 992) {
                    if ($slider.hasClass('slick-initialized')) {
                        $slider.slick('unslick');
                    }
                } else if (!$slider.hasClass('slick-initialized')) {
                    $slider.slick({
                        speed: 1300,
                    });
                    // On before slide change
                    $slider.on('beforeChange', function () {
                        $(this).find('.slick-prev').fadeOut(150);
                    });
                    // On after slide change
                    $slider.on('afterChange', function () {
                        $(this).find('.slick-prev').fadeIn(300);
                    });
                }
            });
        }, 250);
    });

    Section.setRatio();

    Navigation.jumpTo('#home');

    window.berlinovo.elements.sidebar = $('#sidebar-menu');
    window.berlinovo.elements.mobile = $('#msidebar');
    window.berlinovo.elements.sidebarContact = $('#sidebar-contact');
    window.berlinovo.elements.sidebarDownload = $('#sidebar-download');

    if(window.berlinovo.isIE) {
        $('body').addClass('is-ie');
    }

    // check current route
    if (window.location.hash !== '') {
        Navigation.jumpTo(window.location.hash);
    }
});

window.addEventListener("load", function(){
    window.berlinovo.isIE = /*@cc_on!@*/false || !!document.documentMode;
});
