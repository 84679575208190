/* global CONTEXT */
'use strict';

/**
 * debug
 */
export const debug = (msg, obj) => {
    // if (process.env.NODE_ENV !== 'production') {
        if (obj) {
            console.log(msg, obj);
        } else {
            console.log(msg);
        }
    // }
};
