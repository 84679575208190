export const Router = {
    setRoute: function (path) {
        window.location.hash = path;
    },
    getRoute: function () {
        return window.location.hash;
    },
    addDetail: function () {
        if(window.location.hash.search('/detail') === -1) {
            window.location.hash += '/detail';
        }
    },
    removeDetail: function () {
        let route = window.location.hash;
        window.location.hash = route.replace('/detail', '');
    }
}
