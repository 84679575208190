import { Router } from "./router";
import { Section } from "./sections";
import { debug } from "./debug";

// -----------------------------------------------------------------------------
//  Internal functions
// -----------------------------------------------------------------------------
const internal = {

    triggerSlider: function (id) {
        debug('triggerSlider');
        // starting sliders
        $(id).find('.slider').each(function () {
            var $slider = $(this);
            // Initializes a slick carousel only on mobile screens
            if ($(window).width() < 992) {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('unslick');
                    debug('unslick');
                }
                // } else if (!$slider.hasClass('slick-initialized')) {
            } else {
                debug('init');
                $slider.slick({
                    speed: 1300,
                });
                // On before slide change
                $slider.on('beforeChange', function () {
                    $(this).find('.slick-prev').fadeOut(150);
                });
                // On after slide change
                $slider.on('afterChange', function () {
                    $(this).find('.slick-prev').fadeIn(300);
                });
            // } else {
            //     debug('re-init');
            //     $slider.slick('reInit');
            }
        });
    },
    triggerEqualizer: function(id) {
        // equalize heights
        $(id).find('.js-equallize-container').each(function () {
            let highestBox = 0;
            $('.js-equallize', this).each(function () {
                if ($(this).height() > highestBox) {
                    highestBox = $(this).height();
                }
            })

            $('.js-equallize', this).height(highestBox);
        })
    },
};
// -----------------------------------------------------------------------------
//  External functions
// -----------------------------------------------------------------------------
export const Navigation = {

    /**
     * Jump to a certain position
     * @param href
     */
    jumpTo: function (href) {
        // jump to detail
        let detail = false;
        if (href.search('/detail') !== -1) {
            detail = true;
            href = href.replace('/detail', '');
        }

        if (href !== '') {
            window.berlinovo.checkScroll = false; // deactivate scroll detection

            if (window.berlinovo.isIE) {
                window.berlinovo.currentSection = href;
                Section.processSection();
                window.berlinovo.checkScroll = true; // reactivate scroll detection

                if (detail) {
                    Navigation.openDetail(href + '_detail');

                    Router.setRoute(href + '/detail');
                } else {
                    Router.setRoute(href);
                }
            } else {
                $('#sections').scrollTo(href, 800, function () {
                    window.berlinovo.currentSection = href;
                    Section.processSection();
                    setTimeout(function () {
                        window.berlinovo.checkScroll = true; // reactivate scroll detection

                        if (detail) {
                            Navigation.openDetail(href + '_detail');

                            Router.setRoute(href + '/detail');
                        } else {
                            Router.setRoute(href);
                        }
                    }, 66);
                });
            }
        }
    },

    /**
     * Highlight navitem
     *
     * @param identifier
     */
    highlightNav: function (identifier) {
        $('.sidebar-nav__link').removeClass('active');
        $(identifier).addClass('active');
    },

    /**
     * Switch contact
     *
     * @param identifier
     * @deprecated
     */
    switchContact: function (identifier) {
        $('.sub-bar-nav__text--contact').removeClass('visible');
        $(identifier).addClass('visible');
    },

    /**
     * Show detail view
     * @param id
     */
    openDetail: function (id) {

        $('.section--detail').removeClass('visible');
        if($('#sidebar').is(':visible')) {
            $('.sidebar-backlink-detail').show();
        } else {
            $('.msidebar-backlink-detail').show();
        }

        // hide all other detail pages
        $('#detail').find('.section--detail').hide();

        let section = id.replace('#', '').replace('_detail', '');
        debug('section', section);

        if (!berlinovo.preloaded[section + 'Detail']) {
            $.ajax({
                url: "details/" + section + ".html",
                context: document.body
            }).done(function (data) {

                let container = $(id);
                container.find('.section-loader').fadeOut();
                container.hide().html(data).fadeIn();

                debug('Loaded detail ' + section);
                berlinovo.preloaded[section + 'Detail'] = true;

                internal.triggerSlider(id);
                internal.triggerEqualizer(id);

                $('#detail').addClass('visible').scrollTop(0);
            });
        } else {
            $(id).show();
            $('#detail').addClass('visible').scrollTop(0);
        }
        Router.addDetail();
    },

    /**
     * Hide detail view
     */
    closeDetail: function () {
        $('#detail').removeClass('visible');
        if($('#sidebar').is(':visible')) {
            $('.sidebar-backlink-detail').hide();
        } else {
            $('.msidebar-backlink-detail').hide();
        }

        Router.removeDetail();
    },

    openOverlay: function (overlayName) {
        $('#' + overlayName + '-overlay').addClass('visible');
        $('.sidebar-backlink-overlay').show();

        let videoElement = $('#' + overlayName + '-video');
        let fullVar = overlayName + 'Full';

        if (window.berlinovo.videos[fullVar]) {
            // play
            window.berlinovo.videos[fullVar].play();
        } else {
            // init
            videoElement.mediaelementplayer({
                streching: 'streching',
                setDimensions: true,
                success: function (media) {
                    window.berlinovo.videos[fullVar] = media;
                    window.berlinovo.videos[fullVar].play();
                }
            });
        }
    },

    closeOverlay: function () {
        // find opened overlay
        let overlay = $('.section--overlay.visible');
        if (overlay) {
            overlay.removeClass('visible');
            $('.sidebar-backlink-overlay').hide();

            let fullVar = overlay.attr('id').replace('-overlay', '') + 'Full';
            let bgVar = overlay.attr('id').replace('-overlay', '') + 'Bg';

            if (window.berlinovo.videos[fullVar]) {
                window.berlinovo.videos[fullVar].pause();
            }
            if (window.berlinovo.videos[bgVar]) {
                window.berlinovo.videos[bgVar].play();
            }
        }
    },

    /**
     * Check if sidebar is open
     * @returns boolean
     */
    checkSidebar: function () {
        return window.berlinovo.elements.sidebar.hasClass('visible');
    },
    checkOneSidebar: function () {
        return window.berlinovo.elements.sidebar.hasClass('visible') ||
            window.berlinovo.elements.sidebarContact.hasClass('visible') ||
            window.berlinovo.elements.sidebarDownload.hasClass('visible');
    },
    checkSidebarContact: function () {
        return window.berlinovo.elements.sidebarContact.hasClass('visible');
    },
    checkSidebarDownload: function () {
        return window.berlinovo.elements.sidebarDownload.hasClass('visible');
    },

    /**
     * Open sidebar
     */
    openSidebar: function () {
        window.berlinovo.elements.sidebar.addClass('visible');
    },
    toggleMobile: function () {
        if(window.berlinovo.elements.mobile.hasClass('visible')) {
            window.berlinovo.elements.mobile.removeClass('visible');
        } else {
            window.berlinovo.elements.mobile.addClass('visible');
        }
    },
    openSidebarContact: function () {
        window.berlinovo.elements.sidebarContact.addClass('visible');
    },
    openSidebarDownload: function () {
        window.berlinovo.elements.sidebarDownload.addClass('visible');
    },

    /**
     * Close sidebar
     */
    closeSidebar: function () {
        window.berlinovo.elements.sidebar.removeClass('visible');
    },
    closeSidebarContact: function () {
        window.berlinovo.elements.sidebarContact.removeClass('visible');
    },
    closeSidebarDownload: function () {
        window.berlinovo.elements.sidebarDownload.removeClass('visible');
    },
    closeAllSubmenues: function () {
        debug('close all');
        window.berlinovo.elements.sidebar.removeClass('visible');
        window.berlinovo.elements.sidebarContact.removeClass('visible');
        window.berlinovo.elements.sidebarDownload.removeClass('visible');
        window.berlinovo.elements.mobile.removeClass('visible');
    },

}
